<template>
  <div>
    <v-row class="">
      <v-col cols="12" md="10" offset-md="1">
        <v-card flat>
          <v-card-title class="secondary">
            <span class="text-h5 white--text">My contacts</span>
            <v-spacer></v-spacer>
            <v-btn @click="newContactDialog = true" small outlined color="white">
              New contact
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card 
              class="d-sm-none my-6" 
              flat 
              outlined 
              v-for="contact in myContacts" :key="contact.name"
            >
              <v-card-title class="py-1 pr-0 primary darken-4">
                <span class="text-body-1 white--text">{{contact.position}}</span>
                <v-spacer></v-spacer>
                <v-btn @click="editItem(contact)" class="pr-0" color="white" text>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="py-5">
                <div class="text-center">
                  <p class="text-h5 font-weight-bold primary--text text--darken-4">{{contact.name}}</p>
                  <p class="mb-1 text-body-1">{{contact.email}}</p>
                  <p class="text-body-1">{{contact.phone}}</p>
                  <p class="text-caption">Reference: {{contact.referenceNumber}}</p>
                </div>
                <div class="mt-8 d-flex justify-space-between">
                  <v-btn width="30%" depressed outlined color="primary darken-4">
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>                  
                  <v-btn width="30%" depressed color="primary darken-4">
                    <v-icon>mdi-at</v-icon>
                  </v-btn>
                  <v-btn width="30%" depressed outlined color="primary darken-4">
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
            <v-data-table
              class="d-none d-sm-block"
              :headers="headers"
              :items="myContacts"
              hide-default-footer
              :item-class="itemRowColor"
            >
            <template v-slot:item.actions="{ item }">
              <v-btn 
                :small="$vuetify.breakpoint.smAndUp" @click="editItem(item)" text>
                <v-icon
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <!-- <v-btn 
                :small="$vuetify.breakpoint.smAndUp" @click="deleteItem(item)" text>
                <v-icon
                  small
                >
                  mdi-delete
                </v-icon>
              </v-btn> -->
            </template>
            </v-data-table>
          </v-card-text>  
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="editDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text class="pt-3">
          <v-row>
            <v-col>
              <v-text-field
                v-model="editedItem.name"
                label="Name"
              ></v-text-field>
              <v-text-field
                v-model="editedItem.position"
                label="Role"
              ></v-text-field>
              <v-text-field
                v-model="editedItem.email"
                label="Email"
              ></v-text-field>
              <v-text-field
                v-model="editedItem.phone"
                label="Phone"
              ></v-text-field>
              <v-text-field
                v-model="editedItem.referenceNumber"
                label="Reference no."
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end" cols="12">
              <v-btn @click="deleteItem" color="red" text>delete</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="editDialog = false" text color="primary">cancel</v-btn>
              <v-btn class="px-6 ml-5" depressed color="primary" >save</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="newContactDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text class="pt-3">
          <v-row>
            <v-col>
              <v-text-field
                v-model="newContact.name"
                label="Name"
              ></v-text-field>
              <v-text-field
                v-model="newContact.position"
                label="Role"
              ></v-text-field>
              <v-text-field
                v-model="newContact.email"
                label="Email"
              ></v-text-field>
              <v-text-field
                v-model="newContact.phone"
                label="Phone"
              ></v-text-field>
              <v-text-field
                v-model="newContact.referenceNumber"
                label="Reference no."
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end" cols="12">
              <v-btn @click="newContactDialog = false" text color="primary">cancel</v-btn>
              <v-btn class="px-6 ml-5" depressed color="primary" >save</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" outlined @click="deleteDialog = false">Cancel</v-btn>
          <v-btn depressed color="primary" >YES</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: 'userContacts',
  data() {
    return {
      newContactDialog: false,
      editDialog: false,
      deleteDialog: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Role', value: 'position' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Reference ', value: 'referenceNumber' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      editedIndex: -1,
      newContact: {
        name: '',
        position: '',
        email: '',
        phone: '',
        referenceNumber: ''
      },
      editedItem: {
        name: '',
        position: '',
        email: '',
        phone: '',
        referenceNumber: ''
      },
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.myContacts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editDialog = true
    },
    deleteItem() {
      this.deleteDialog = true
    },
    // colors alternative table rows on anything larger than mobile!!!
    itemRowColor(item) {
      const itemIndex = this.myContacts.indexOf(item)
      if(itemIndex % 2 === 0) return ''
      return 'light-blue lighten-5'
    }
  },
  computed: {
    ...mapState({
      myContacts: state => state.myContacts
    }),
  }
}
</script>

<style>
/* Used to style table on mobile */

   .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row td {
    font-size: 1.2rem;
  }

  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row:nth-child(even) td {
    background: #ECEFF1;
  }


</style>